const SEO = [
	{
		page: "home",
		description:
			"Product Manager. When I’m not working, you’ll probably find me travelling somewhere far from home, touching grass, and making content",
		keywords: ["Pavan", "Pavan K", "Pavan Khiani"],
	}
];

export default SEO;
